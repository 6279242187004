<template>
  <div>
    <p class="loading" :style="[black ? {'color':'black'} : {'color':'#eef1f3'}]">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LoadingDots",
  props: {
    black: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loading p {
  margin: 0;
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
}
.loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.loading span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading span:nth-child(3) {
  animation-delay: 0.4s;
}
</style>